import styled from 'styled-components'
import media from 'styled-media-query'
import BackgroundImage from 'gatsby-background-image'

export const HighlightsContainer = styled.div`
  height: calc(100vh - ${props => props.theme.headerHeight.desktop});
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.lessThan('huge')`
    height: auto;
    margin: 4rem 0;
  `}

  @media only screen and (max-width: 320px) {
    height: auto;
    margin: 2rem 0;
  }
`

export const HighlightsGridContainer = styled.div`
  height: 44.8rem;
  display: grid;
  grid-template-columns: repeat(4, 3fr);
  gap: 1.2rem;
  grid-template-areas:
    'highlight1 highlight1 highlight2 highlight2'
    'highlight1 highlight1 highlight3 highlight4';

  ${media.lessThan('medium')`
    height: auto;
    grid-template-columns: repeat(2, 6fr);;
    grid-template-areas:
      'highlight1 highlight1'
      'highlight2 highlight2'
      'highlight3 highlight4';
  `}
`

export const HighlightCard = styled.div`
  position: relative;

  &:nth-of-type(1) {
    grid-area: highlight1;

    ${media.lessThan('medium')`
      height: 37.2rem;
    `}
  }

  &:nth-of-type(2) {
    grid-area: highlight2;

    ${media.lessThan('medium')`
      height: 18rem;
    `}
  }

  &:nth-of-type(3) {
    grid-area: highlight3;

    ${media.lessThan('medium')`
      height: 18rem;
    `}
  }

  &:nth-of-type(4) {
    grid-area: highlight4;

    ${media.lessThan('medium')`
      height: 18rem;
    `}
  }
`

export const HighlightsCardOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
`

export const HighlightImage = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
`

export const HighlightContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;

  &.card-1 {
    margin: 0 ${props => props.theme.container.desktop} 3.2rem
      ${props => props.theme.container.desktop};

    ${media.lessThan('medium')`
      margin: 0  ${props => props.theme.container.mobile} ${props => props.theme.container.mobile};
    `}

    h2 {
      font-size: 3.2rem;
      line-height: 4rem;

      ${media.lessThan('medium')`
        font-size: 2.4rem;
        line-height: 3.2rem;
      `}
    }
  }

  &.card-2 {
    margin: 0 4.8rem 2.4rem 2.4rem;

    ${media.lessThan('medium')`
      margin: 0  ${props => props.theme.container.mobile} 1.6rem;
    `}

    h2 {
      font-size: 2.4rem;
      line-height: 3.2rem;

      ${media.lessThan('medium')`
        font-size: 2rem;
        line-height: 2.4rem;
      `}
    }
  }

  &.card-3,
  &.card-4 {
    h2 {
      font-size: 2rem;
      line-height: 2.4rem;

      ${media.lessThan('medium')`
        font-size: 1.6rem;
        line-height: 2rem;
      `}
    }

    margin: 0 4.8rem 2.4rem 2.4rem;

    ${media.lessThan('medium')`
      margin: 0  ${props => props.theme.container.mobile} 1.6rem;
    `}
  }
`

export const HighlightTitle = styled.h2`
  font-family: 'Dasa Sans';
  font-weight: 600;
  color: ${props => props.theme.colors.white};
  margin: 0 0 0.8rem;
`
