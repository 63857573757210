import styled from 'styled-components'
import media from 'styled-media-query'

export const FilterContainer = styled.div`
  display: flex;
  margin-bottom: 4.8rem;

  ${media.lessThan('small')`
    margin-bottom: 2.4rem;
  `}
`

export const FilterOptions = styled.div`
  width: 28.8rem;
  cursor: pointer;
  padding-bottom: 0.8rem;
  text-align: center;

  ${media.lessThan('small')`
    width: 50%;
  `}
`

export const FilterOption = styled.h3`
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: normal;
  margin: 0;

  ${media.lessThan('medium')`
    font-size: 1.6rem;
    line-height: 2.4rem;
  `}
`
