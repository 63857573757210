import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { useRegion } from '../../../hooks/useRegion'
import { AllUnitsService } from '../../../utils/unityUtils'

import { useTranslations } from 'gatsby-plugin-translate'

import { Container } from '../../shared/container'
import SectionTitle from '../../shared/SectionTitle'
import StateFilter from '../StateFilter'
import filterOptions from '../filterOptions'
import UnitsCarousel from './UnitsCarousel'

import * as S from './styled'

const Units = ({ unitsData: unitsWpData }) => {
  const { getRegionOption } = useRegion()

  const data = useStaticQuery(graphql`
    {
      allUnities {
        nodes {
          id
          name
          address {
            number
            logradouro
            neighborhood
            zipCode
            city
            stateInitials
          }
        }
      }
    }
  `)

  const t = useTranslations()
  const translatedSectionTitle = t`Unidades`
  const translatedShowAll = t`Ver tudo`

  const [unitsDpData, setUnitsDpData] = useState([])

  useEffect(() => {
    setUnitsDpData(AllUnitsService(data))
  }, [])

  return (
    <S.UnitsContainer>
      <Container>
        <SectionTitle
          title={translatedSectionTitle}
          url="/unidades"
          label={translatedShowAll}
          white
        />
        <StateFilter
          filterOptions={filterOptions}
        />
      </Container>
      <S.ContainerCustom>
        <UnitsCarousel
          unitsWpData={unitsWpData}
          unitsDpData={unitsDpData}
          stateCheckedValue={getRegionOption()}
        />
      </S.ContainerCustom>
    </S.UnitsContainer>
  )
}

Units.propTypes = {
  unitsData: PropTypes.array.isRequired,
}

export default Units
