import React, { useState } from 'react'
import PropTypes from 'prop-types'

import * as S from './styled'

const FeaturedInformation = ({ informationData: { show, text, link } }) => {
  const [closeInformation, setCloseInformation] = useState(false)
  return (
    <>
      {show && (
        <S.InformationContainer close={closeInformation}>
          <S.InformationText>
            {text}{' '}
            <S.InformationLinkText href={link.url} target={link.target}>
              {link.title}
            </S.InformationLinkText>
          </S.InformationText>
          <S.CloseInformationIcon
            onClick={() => {
              setCloseInformation(true)
            }}
          />
        </S.InformationContainer>
      )}
    </>
  )
}

FeaturedInformation.propTypes = {
  informationData: PropTypes.object.isRequired,
}

export default FeaturedInformation
