import styled from 'styled-components'
import media from 'styled-media-query'
import { Card, CardImg, CardTitle, CardDescription } from '../../shared/home-cards-styled'

export const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2.7rem;
  margin: 4.8rem 0;

  ${media.greaterThan('huge')`
    grid-template-columns: repeat(4, 1fr);
  `}

  ${media.lessThan('medium')`
    grid-template-columns: repeat(1, 1fr);
  `}
`

export const UnitCard = styled(Card)`
  box-shadow: ${props =>
    props.hasWhiteBg ? "0px 5px 10px rgb(0 0 0 / 10%)" : "none"};
`

export const UnitImage = styled(CardImg)``

export const UnitInfo = styled.div`
  padding: ${props =>
    props.hasWhiteBg ? "0 1.2rem 1.2rem" : "0"};

`

export const UnitTitle = styled(CardTitle)`
  color: ${props =>
    props.hasWhiteBg ? props.theme.colors.secondary : props.theme.colors.white};
`

export const UnitAdress = styled(CardDescription)`
  color: ${props =>
    props.hasWhiteBg ? props.theme.colors.secondary : props.theme.colors.white};
`
