import React from 'react'
import { useGeographicalState } from '../../../hooks/useGeographicalState'
import slug from 'slug'

import * as S from './styled'

const StateSelector = ({ hasWhiteBg }) => {
  const { setGeographicalState, getGeographicalState } = useGeographicalState()

  const changeState = state => {
    if (state === getGeographicalState()) {
      return
    }
    setGeographicalState(state)
  }

  const isActive = state => state === getGeographicalState()

  return (
    <S.StaffFilterContainer>
      {['São Paulo', 'Rio de Janeiro'].map(state => (
        <S.StaffOptions
          key={state}
          data-testid={`selector-${slug(state)}`}
          isActive={isActive(state)}
          onClick={() => changeState(state)}
        >
          <S.StaffOption>{state}</S.StaffOption>
        </S.StaffOptions>
      ))}
    </S.StaffFilterContainer>
  )
}

export default StateSelector
