import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { RegionProvider } from '../hooks/useRegion'
import { GeographicalStateProvider } from '../hooks/useGeographicalState'

// import { useTranslateContext } from 'gatsby-plugin-translate'

import Layout from '../components/layout'
import SEO from '../components/seo'
import HomeBanner from '../components/HomeBanner'
import Institutional from '../components/Institutional/Home'
import Differentials from '../components/Differentials/Home'
import Units from '../components/Units/Home'
import ClinicalStaff from '../components/ClinicalStaff/Home'
import Highlights from '../components/Highlights/Home'

const IndexPage = ({ data, location, pageContext }) => {
  // const { language } = useTranslateContext()

  const wordpressPage = data.wordpressWpGatsbyPage.acf
  // tardução
  // const wordpressPage =
  //   language === 'pt-br' ? data.wordpressWpGatsbyPage.acf : data.wordpressWpGatsbyPageEn.acf

  const homeBannerData = {
    image: wordpressPage.main_banner.localFile.childImageSharp,
    imageMobile: wordpressPage.main_mobile_banner.localFile.childImageSharp,
    link: wordpressPage.link_banner.url,
    altImage: wordpressPage.main_banner.alt_text,
    smallText: wordpressPage.banner_small_text,
    highlightText: wordpressPage.banner_highlight_text,
  }

  const informationData = {
    show: wordpressPage.show_information,
    text: wordpressPage.information_text,
    link: wordpressPage.information_link,
  }

  const institutionalData = {
    description: wordpressPage.institutional_description,
    image: wordpressPage.institutional_image.localFile.childImageSharp,
    altImage: wordpressPage.institutional_image.alt_text,
  }
  const differentialsData = data.allWordpressAcfDifferentialsPage.edges

  // tardução
  // const differentialsData =
  //   language === 'pt-br'
  //     ? data.allWordpressAcfDifferentialsPage.edges
  //     : data.allWordpressAcfDifferentialsPageEn.edges

  const unitsData = data.allWordpressAcfUnitsPage.edges

  const clinicalStaffData = data.allWordpressWpClinicalStaffPage.edges

  const highlightsData = data.allWordpressPost.edges

  // tardução
  // const highlightsData =
  //   language === 'pt-br' ? data.allWordpressPost.edges : data.allWordpressPostEn.edges

  const settings = data.settings

  return (
    <Layout location={location} pageContext={pageContext} hasBreadcrumb={false}>
      <SEO
        title={`${settings.brand_name} | ${wordpressPage.title}`}
        description={wordpressPage.description}
        canonical={wordpressPage.canonical}
      />
      <HomeBanner homeBannerData={homeBannerData} informationData={informationData} />
      <Institutional institutionalData={institutionalData} />
      <Differentials differentialsData={differentialsData} />
      <RegionProvider>
        <Units unitsData={unitsData} />
      </RegionProvider>
      <GeographicalStateProvider>
        <ClinicalStaff clinicalStaffData={clinicalStaffData} />
      </GeographicalStateProvider>
      <Highlights highlightsData={highlightsData} />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}
export default IndexPage

export const pageQuery = graphql`
  query {
    wordpressWpGatsbyPage(slug: { eq: "home" }) {
      acf {
        title
        description
        canonical
        show_information
        information_text
        information_link {
          target
          title
          url
        }
        banner_highlight_text
        banner_small_text
        main_banner {
          alt_text
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        main_mobile_banner {
          alt_text
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        link_banner {
          url
        }
        institutional_description
        institutional_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    allWordpressPost(limit: 4, sort: { fields: date, order: DESC }) {
      edges {
        node {
          author
          categories
          content
          date
          slug
          title
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    allWordpressAcfDifferentialsPage(
      limit: 3
      sort: { fields: acf___inclusion_date, order: DESC }
    ) {
      edges {
        node {
          acf {
            service_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            service_description
            service_title
            service_link {
              target
              title
              url
            }
          }
        }
      }
    }
    allWordpressAcfUnitsPage {
      edges {
        node {
          acf {
            unit_id
            unit_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            unit_name
            unit_state
            unit_video
          }
        }
      }
    }
    allWordpressWpClinicalStaffPage(sort: { fields: title }) {
      edges {
        node {
          title
          slug
          acf {
            acting_region
            title_gender
            council_number
            in_spotlight {
              picture {
                alt_text
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    settings: wordpressAltaThemeSettings {
      brand_name
      brand_url
    }
  }
`

// graphql com traduções
// export const pageQuery = graphql`
//   query {
//     wordpressWpGatsbyPage(slug: { eq: "home" }) {
//       acf {
//         title
//         description
//         show_information
//         information_text
//         information_link {
//           target
//           title
//           url
//         }
//         banner_highlight_text
//         banner_small_text
//         main_banner {
//           localFile {
//             childImageSharp {
//               fluid(quality: 100, maxWidth: 1920) {
//                 ...GatsbyImageSharpFluid_withWebp
//               }
//             }
//           }
//         }
//         institutional_description
//         institutional_image {
//           localFile {
//             childImageSharp {
//               fluid(quality: 100) {
//                 ...GatsbyImageSharpFluid_withWebp
//               }
//             }
//           }
//         }
//       }
//     }
//     wordpressWpGatsbyPageEn(slug: { eq: "home" }) {
//       acf {
//         title
//         description
//         show_information
//         information_text
//         information_link {
//           target
//           title
//           url
//         }
//         banner_highlight_text
//         banner_small_text
//         main_banner {
//           localFile {
//             childImageSharp {
//               fluid(quality: 100, maxWidth: 1920) {
//                 ...GatsbyImageSharpFluid_withWebp
//               }
//             }
//           }
//         }
//         institutional_description
//         institutional_image {
//           localFile {
//             childImageSharp {
//               fluid(quality: 100) {
//                 ...GatsbyImageSharpFluid_withWebp
//               }
//             }
//           }
//         }
//       }
//     }
//     allWordpressPost(limit: 4, sort: { fields: date, order: DESC }) {
//       edges {
//         node {
//           author
//           categories
//           content
//           date
//           slug
//           title
//           featured_media {
//             localFile {
//               childImageSharp {
//                 fluid(quality: 100, maxWidth: 1920) {
//                   ...GatsbyImageSharpFluid_withWebp
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//     allWordpressPostEn(limit: 4, sort: { fields: date, order: DESC }) {
//       edges {
//         node {
//           author
//           categories
//           content
//           date
//           slug
//           title
//           featured_media {
//             localFile {
//               childImageSharp {
//                 fluid(quality: 100, maxWidth: 1920) {
//                   ...GatsbyImageSharpFluid_withWebp
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//     allWordpressAcfDifferentialsPage(
//       limit: 3
//       sort: { fields: acf___inclusion_date, order: DESC }
//     ) {
//       edges {
//         node {
//           acf {
//             service_image {
//               localFile {
//                 childImageSharp {
//                   fluid(quality: 100, maxWidth: 400) {
//                     ...GatsbyImageSharpFluid_withWebp
//                   }
//                 }
//               }
//             }
//             service_description
//             service_title
//             service_link {
//               target
//               title
//               url
//             }
//           }
//         }
//       }
//     }
//     allWordpressAcfDifferentialsPageEn(
//       limit: 3
//       sort: { fields: acf___inclusion_date, order: DESC }
//     ) {
//       edges {
//         node {
//           acf {
//             service_image {
//               localFile {
//                 childImageSharp {
//                   fluid(quality: 100, maxWidth: 400) {
//                     ...GatsbyImageSharpFluid_withWebp
//                   }
//                 }
//               }
//             }
//             service_description
//             service_title
//             service_link {
//               target
//               title
//               url
//             }
//           }
//         }
//       }
//     }
//     allWordpressAcfUnitsPage {
//       edges {
//         node {
//           acf {
//             unit_id
//             unit_image {
//               localFile {
//                 childImageSharp {
//                   fluid(quality: 100, maxWidth: 400) {
//                     ...GatsbyImageSharpFluid_withWebp
//                   }
//                 }
//               }
//             }
//             unit_name
//             unit_state
//             unit_video
//           }
//         }
//       }
//     }
//     allWordpressAcfClinicalStaffPage(sort: { fields: acf___name, order: ASC }) {
//       edges {
//         node {
//           acf {
//             city
//             council_number
//             name
//             specialization_area
//             title_gender
//             photo {
//               localFile {
//                 childImageSharp {
//                   fluid(quality: 100, maxWidth: 400) {
//                     ...GatsbyImageSharpFluid_withWebp
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `
