import slug from 'slug'
import { capitalize } from '@cms/utils'

const mapUnits = unit => {
  return {
    name: unit.name,
    id: unit.id,
    address: `${unit.address.logradouro}, ${unit.address.number} - ${unit.address.neighborhood}`,
    simpleAddress: `${unit.address.logradouro}, ${unit.address.number}`,
    city: `${unit.address.city} - ${unit.address.stateInitials}, ${unit.address.zipCode}`,
    state: `${unit.address.stateInitials}`,
    slug: `/unidades/${slug(unit.name)}`,
    googleDirectionsLink: unit.googleDirectionsLink,
  }
}

const parseObjectIntoSelectArray = value => {
  return Object.keys(value).map(key => {
    const group = value[key]

    return {
      text: key,
      value: group,
    }
  })
}

const groupExam = data => {
  const groupedAsObject = data.reduce((groupObject, unity) => {
    const { examsPerformed } = unity

    if (!examsPerformed || examsPerformed.length < 0) return groupObject

    for (let i = 0; i < examsPerformed.length - 1; i++) {
      if (!groupObject[examsPerformed[i]]) groupObject[examsPerformed[i]] = [unity]
      else groupObject[examsPerformed[i]].push(unity)
    }

    return groupObject
  }, {})

  return parseObjectIntoSelectArray(groupedAsObject)
}

const groupFacility = data => {
  const groupedAsObject = data.reduce((groupObject, unity) => {
    const { facilities } = unity
    const parsedFacilities = facilities.map(e => e.nome)

    if (!parsedFacilities || parsedFacilities.length < 0) return groupObject

    for (let i = 0; i < parsedFacilities.length - 1; i++) {
      if (!groupObject[parsedFacilities[i]]) groupObject[parsedFacilities[i]] = [unity]
      else groupObject[parsedFacilities[i]].push(unity)
    }

    return groupObject
  }, {})

  return parseObjectIntoSelectArray(groupedAsObject)
}

const groupRegion = data => {
  const groupedAsObject = data.reduce((groupObject, unity) => {
    const fullObject = unity
    const regionName = unity.regionName

    if (!unity.regionName)
      return groupObject

    if (!groupObject[regionName])
      return {
        ...groupObject,
        [regionName]: [fullObject],
      }

    return {
      ...groupObject,
      [regionName]: [...groupObject[regionName], fullObject],
    }
  }, {})

  return parseObjectIntoSelectArray(groupedAsObject)
}

const filterUnits = unit => !/Sem previsão/i.test(unit.name) && unit.cidade !== null

const sortUnits = (a, b) => {
  if (a.name > b.name) {
    return 1
  }
  if (a.name < b.name) {
    return -1
  }
  return 0
}

const parseUnities = data => data.filter(filterUnits).map(mapUnits).sort(sortUnits)

const mapAndSort = data => data.filter(filterUnits).map(mapUnits).sort(sortUnits)

const AllUnitsService = data => {
  const d = data.allUnities.nodes
  return parseUnities(d)
}

const sortFilterByName = data => data.sort((a, b) => (a.text > b.text ? 1 : -1))

const filterGroups = data => {
  const d = data.allUnities.nodes
  const regionFilter = [
    { text: 'Filtre por Região', value: {} },
    ...sortFilterByName(groupRegion(d)),
  ]
  const examFilter = [
    { text: 'Filtre por Exames ou Serviços', value: {} },
    ...sortFilterByName(groupExam(d)),
  ]
  const facilityFilter = [
    { text: 'Filtre por Facilidade', value: {} },
    ...sortFilterByName(groupFacility(d)),
  ]

  return {
    regionFilter,
    examFilter,
    facilityFilter,
  }
}

export const getHoursByGrouping = (data, idType) => {
  return data
    .filter(e => e.idTipo === idType)
    .map(e => `${e.tipoHorario.nome}: das ${e.horarioInicio} as ${e.horarioFim}`)
}

const parseSingleUnityData = data => {
  return {
    name: capitalize(data.name),
    id: data.id,
    address: capitalize(
      `${data.address.logradouro}, ${data.address.number} - ${data.address.neighborhood}`
    ),
    city: capitalize(
      `${data.address.city && `${data.address.city}`}${(data.address.city && data.address.stateInitials) && ' - '}${data.address.stateInitials} ${data.address.zipCode && `, ${data.address.zipCode}`} `
    ),
    googleMapsInfo: {},
    facilities: data.facilities,
    examsPerformed: data.examsPerformed,
    hasDriveThru: data.facilities.find(unity => unity.nome === "Atendimento Drive-Thru"),
    businessHours: getHoursByGrouping(data.serviceHours, 4),
    businessHoursDriveThru: getHoursByGrouping(data.serviceHours, 11),
    businessMaterialColetionHours: getHoursByGrouping(data.serviceHours, 1),
    mapsProps: {
      center: {
        lat: data.address.position?.latitude,
        lng: data.address.position?.longitude,
      },
      zoom: 16,
    },
  }
}

export { filterGroups, AllUnitsService, mapAndSort, parseSingleUnityData }
