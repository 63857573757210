import React from 'react'
import PropTypes from 'prop-types'

import { useTranslations } from 'gatsby-plugin-translate'

import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'

import { ArrowLeft, ArrowRight } from '../../../shared/CarouselArrow'
import { ShowMore } from '../../../shared/ShowMore'

import * as S from '../styled'

const DifferentialsMobile = ({ differentialsData }) => {
  const t = useTranslations()
  const translatedShowMore = t`Ver mais`

  return (
    <S.CardsWrapper>
      <Carousel
        addArrowClickHandler
        arrowRightDisabled={<></>}
        arrowRight={<ArrowRight differentials />}
        arrowLeftDisabled={<></>}
        arrowLeft={<ArrowLeft differentials />}
        slidesPerPage={1}
        centered
      >
        {differentialsData.map(({ node: { acf: item } }, index) => (
          <S.Card key={index}>
            <S.Image
              fluid={item.service_image.localFile.childImageSharp.fluid}
              alt={item.service_image.alt_text}
            />
            <S.CardTitle>{item.service_title}</S.CardTitle>
            <S.CardDescription>{item.service_description}</S.CardDescription>
            <ShowMore url={item.service_link.url} label={translatedShowMore} />
          </S.Card>
        ))}
      </Carousel>
    </S.CardsWrapper>
  )
}

DifferentialsMobile.propTypes = {
  differentialsData: PropTypes.array.isRequired,
}

export default DifferentialsMobile
