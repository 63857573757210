import styled, { keyframes } from 'styled-components'
import Img from 'gatsby-image'
import { fadeIn } from 'react-animations'

const fadeAnimation = keyframes`${fadeIn}`

export const Card = styled.div`
  animation: 1s ${fadeAnimation};
  width: 100%;
`

export const CardImg = styled(Img)`
  width: 100%;
  height: 22.4rem;
`

export const CardTitle = styled.h4`
  font-size: 2.4rem;
  line-height: 2.8rem;
  font-weight: normal;
  margin: 2rem 0 0.8rem;
`

export const CardDescription = styled.p`
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-weight: normal;
  margin: 0 0 2.4rem;
`
