import React from 'react'
import PropTypes from 'prop-types'

import { useTranslations } from 'gatsby-plugin-translate'

import { ShowMore } from '../../shared/ShowMore'

import * as S from './styled'

const Institutional = ({ institutionalData: { description, image, altImage } }) => {
  const t = useTranslations()
  const translatedSectionTitle = t`Alta Excelência Diagnóstica`
  const translatedShowMore = t`Conheça o Alta`

  return (
    <S.InstitutionalGrid>
      <S.InstitutionalContainer>
        <S.InstitutionalTitle>{translatedSectionTitle}</S.InstitutionalTitle>
        <S.InstitutionalDescription dangerouslySetInnerHTML={{ __html: description }} />
        <ShowMore
          url="/sobre-o-alta-excelencia-diagnostica"
          label={translatedShowMore}
          institutional
        />
      </S.InstitutionalContainer>
      <S.InstitutionalImage fluid={image.fluid} alt={altImage} />
    </S.InstitutionalGrid>
  )
}

Institutional.propTypes = {
  institutionalData: PropTypes.object.isRequired,
}

export default Institutional
