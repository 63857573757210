import React from 'react'
import PropTypes from 'prop-types'

import { useTranslations } from 'gatsby-plugin-translate'

import { ShowMore } from '../../../shared/ShowMore'

import * as S from '../styled'

const DifferentialsDesktop = ({ differentialsData }) => {
  const t = useTranslations()
  const translatedShowMore = t`Ver mais`

  return (
    <S.CardsWrapper>
      {differentialsData.map(({ node: { acf: item } }, index) => (
        <S.Card key={index}>
          <S.Image
            fluid={item.service_image.localFile.childImageSharp.fluid}
            alt={item.service_image.alt_text}
          />
          <S.CardTitle>{item.service_title}</S.CardTitle>
          <S.CardDescription>{item.service_description}</S.CardDescription>
          <ShowMore url={item.service_link.url} label={translatedShowMore} />
          {/* <ShowMore url={`/servicos${item.service_link.url}`} label={translatedShowMore} /> */}
        </S.Card>
      ))}
    </S.CardsWrapper>
  )
}

DifferentialsDesktop.propTypes = {
  differentialsData: PropTypes.array.isRequired,
}

export default DifferentialsDesktop
