import React from 'react'

import * as S from './styled'

const ShowMore = ({ url, label, isWhiteColor, institutional }) => {
  const isExternalLink = url.match(/^https?:\/\//g)
  return (
    <S.ShowMoreLink
      href={url}
      target={isExternalLink ? '_blank' : ''}
      isWhiteColor={isWhiteColor}
      institutional={institutional}
    >
      {label}
      <S.ArrowRightIcon size={institutional ? 26 : 22} isWhiteColor={isWhiteColor} />
    </S.ShowMoreLink>
  )
}

export default ShowMore
