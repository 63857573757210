import styled from 'styled-components'
import media from 'styled-media-query'

import { KeyboardArrowRight } from '@styled-icons/material-rounded'

export const ShowMoreLink = styled.a`
  width: max-content;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${props => (props.isWhiteColor ? props.theme.colors.white : props.theme.colors.secondary)};
  font-weight: normal;
  font-size: ${props => (props.institutional ? '2rem' : ' 1.4rem')};
  line-height: 1.6rem;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  ${media.lessThan('medium')`
    opacity: 1;
  `}
`

export const ArrowRightIcon = styled(KeyboardArrowRight)`
  color: ${props => (props.isWhiteColor ? props.theme.colors.white : props.theme.colors.secondary)};
  margin-left: 1rem;
  cursor: pointer;
`
