import styled from 'styled-components'
import media from 'styled-media-query'
import { Container } from '../../shared/container'

export const UnitsContainer = styled.div`
  height: calc(100vh - ${props => props.theme.headerHeight.desktop});
  background-color: ${props => props.theme.colors.secondary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  h2 {
    color: ${props => props.theme.colors.primary};
  }

  a {
    color: ${props => props.theme.colors.white};
    svg {
      color: ${props => props.theme.colors.white};
    }
  }

  ${media.between('medium', 'huge')`
    height: auto;
    padding: 4rem 0;
  `}

  ${media.lessThan('medium')`
    height: calc(100vh - ${props => props.theme.headerHeight.mobile});
  `}

  @media only screen and (max-width: 320px) {
    height: auto;
    padding: 2rem 0;
  }
`

export const ContainerCustom = styled(Container)`
  margin-right: 0 !important;
  position: relative;

  ul {
    align-items: baseline;

    li {
      &:first-of-type {
        margin-left: 0 !important;
      }

      &:last-of-type {
        margin-right: 0 !important;
      }
    }
  }
`
