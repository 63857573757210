import styled from 'styled-components'
import { FilterContainer, FilterOptions, FilterOption } from '../../shared/state-filter-styled'

export const UnitsFilterContainer = styled(FilterContainer)``

export const UnitsOptions = styled(FilterOptions)`
  color: ${props => props.hasWhiteBg ?
    [props.theme.colors.secondary, props.theme.colors.primary][Number(props.isActive)] :
    [props.theme.colors.white, props.theme.colors.primary][Number(props.isActive)]
  };
  border-bottom: 2px solid ${props => props.hasWhiteBg ?
    [props.theme.colors.secondary, props.theme.colors.primary][Number(props.isActive)] :
    [props.theme.colors.white, props.theme.colors.primary][Number(props.isActive)]
  };



  &:hover {
    color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
  }
`

export const UnitsOption = styled(FilterOption)``
