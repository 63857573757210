import styled from 'styled-components'
import { Card, CardImg, CardTitle, CardDescription } from '../../../shared/home-cards-styled'

export const ProfessionalCard = styled(Card)``

export const ProfessionalPhoto = styled(CardImg)``

export const ProfessionalName = styled(CardTitle)`
  color: ${props => props.theme.colors.secondary};
`

export const ProfessionalCouncilNumber = styled(CardDescription)`
  color: ${props => props.theme.colors.secondary};
`
