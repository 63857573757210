import styled, { keyframes } from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'
import { Container } from '../../shared/container'
import { fadeIn } from 'react-animations'

const fadeAnimation = keyframes`${fadeIn}`

export const DifferentialsContainer = styled(Container)`
  height: calc(100vh - ${props => props.theme.headerHeight.desktop});
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  color: ${props => props.theme.colors.secondary};

  ${media.between('medium', 'huge')`
    height: auto !important;
    padding: 4rem 0;
  `}

  ${media.lessThan('medium')`
    height: calc(100vh - ${props => props.theme.headerHeight.mobile});
  `}

  @media only screen and (max-width: 320px) {
    height: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 4fr);
  gap: 4.8rem;
  color: ${props => props.theme.colors.secondary};


  ${media.greaterThan('huge')`
    gap: 12rem;
  `}

  ${media.lessThan('medium')`
    grid-template-columns: 12fr;


    ul {
      align-items: baseline;
      li {
        &:first-of-type {
          margin-left: 0 !important;
        }

        &:last-of-type {
          margin-right: 0 !important;
        }
      }
    }
  `}
`

export const Card = styled.div`
  animation: 1s ${fadeAnimation};
  width: 100%;
`

export const Image = styled(Img)`
  width: 100%;
  height: 24.8em;

  ${media.greaterThan('huge')`
    height: 32rem;
  `}

  ${media.between('small', 'medium')`
    height: 50rem;
  `}
`

export const CardTitle = styled.h3`
  font-family: 'Dasa Sans';
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-style: normal;
  font-weight: normal;
  margin: 1.8rem 0 0.8rem;
  color: ${props => props.theme.colors.black};
`

export const CardDescription = styled.p`
  font-family: 'Dasa Sans';
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: normal;
  margin: 0 0 1.8rem;
  color: ${props => props.theme.colors.brownishGrey};
`
