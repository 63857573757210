import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'
import { Container } from '../../shared/container'

export const InstitutionalGrid = styled.div`
  height: calc(100vh - ${props => props.theme.headerHeight.desktop});
  display: grid;
  grid-template-columns: 7fr 5fr;
  grid-template-rows: 1fr;
  gap: 0 14.4rem;

  ${media.lessThan('large')`
    height: auto;
    margin: 5rem 0;
    grid-template-columns: 12fr;
    gap: 3.8rem 0;
  `}

  ${media.lessThan('medium')`
    margin: 0;
  `}
`

export const InstitutionalContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  ${media.greaterThan('large')`
    margin-right: 0;
  `}

  ${media.between('medium', 'large')`

  `}

  ${media.lessThan('medium')`
    margin-top: 3rem;
  `}
`

export const InstitutionalTitle = styled.h1`
  font-family: 'Dasa Sans';
  font-size: 4rem;
  font-weight: normal;
  line-height: 4.8rem;
  color: ${props => props.theme.colors.tertiary};
  margin: 0;

  ${media.lessThan('medium')`
    font-size: 3.2rem;
    line-height: 4rem;
  `}
`

export const InstitutionalDescription = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 4rem 0;

    ${media.lessThan('medium')`
      margin: 2.4rem 0;
    `}
  }

  font-family: 'Dasa Sans';
  font-size: 2rem;
  line-height: 2.8rem;
  color: ${props => props.theme.colors.brownishGrey};

  ${media.lessThan('medium')`
    font-size: 1.6rem;
    line-height: 2.4rem;
  `}
`

export const InstitutionalImage = styled(Img)`
  height: 100%;

  ${media.between('medium', 'large')`
    width: 50%;
    justify-self: center;

    > div {
      padding-bottom: 100% !important;
    }
  `}

  ${media.lessThan('medium')`
    width: 100%;
    height: 32.4rem;
  `}
`
