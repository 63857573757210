import React from 'react'
import { useGeographicalState } from '../../../hooks/useGeographicalState'
import { useTranslations } from 'gatsby-plugin-translate'

import { Container } from '../../shared/container'
import SectionTitle from '../../shared/SectionTitle'
import { StateSelector } from '../StateSelector'
import ClinicalStaffCarousel from './ClinicalStaffCarousel'

import * as S from './styled'

const ClinicalStaff = ({ clinicalStaffData }) => {
  const t = useTranslations()
  const translatedSectionTitle = t`Corpo clínico`
  const translatedShowAll = t`Ver tudo`

  const { getGeographicalState } = useGeographicalState()

  return (
    <S.ClinicalStaffContainer>
      <Container>
        <SectionTitle
          title={translatedSectionTitle}
          url="/corpo-clinico"
          label={translatedShowAll}
        />
        <StateSelector />
      </Container>

      <S.ContainerCustom>
        <ClinicalStaffCarousel
          clinicalStaffData={clinicalStaffData}
          stateCheckedValue={getGeographicalState()}
        />
      </S.ContainerCustom>
    </S.ClinicalStaffContainer>
  )
}

export default ClinicalStaff
