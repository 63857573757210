import styled from 'styled-components'
import media from 'styled-media-query'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'

export const InformationContainer = styled.div`
  font-family: 'Dasa Sans';
  display: ${props => (props.close ? 'none' : 'flex')};
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: ${props => props.theme.colors.mediumTan};
  padding: 2rem 4.8rem;

  ${media.lessThan('medium')`
    padding: 1.6rem 2.4rem;
  `}

  @media only screen and (max-width: 320px) {
    padding: 1.2rem 2.4rem;
  }
`

export const InformationText = styled.p`
  font-size: 2.2rem;
  line-height: 3.2rem;
  font-weight: bold;
  margin: 0;
  color: ${props => props.theme.colors.white};

  ${media.between('medium', 'huge')`
    font-size: 1.8rem;
  `}

  ${media.lessThan('small')`
    width: 85%;
    font-size: 1.8rem;
    line-height: 2.4rem;
  `}

  @media only screen and (max-width: 320px) {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`

export const InformationLinkText = styled.a`
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  ${media.lessThan('medium')`
    opacity: 1;
  `}
`

export const CloseInformationIcon = styled(CloseOutline)`
  color: ${props => props.theme.colors.white};
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  ${media.lessThan('medium')`
    width: 4rem;
    height: 4rem;
    opacity: 1;
  `}

  @media only screen and (max-width: 320px) {
    width: 3rem;
    height: 3rem;
  }
`
