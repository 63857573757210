import React from 'react'
import PropTypes from 'prop-types'

import { useTranslations } from 'gatsby-plugin-translate'

import { Container } from '../../shared/container'
import SectionTitle from '../../shared/SectionTitle'
import { ShowMore } from '../../shared/ShowMore'
import * as S from './styled'

const Highlights = ({ highlightsData }) => {
  const t = useTranslations()

  const translatedSectionTitle = t`Destaques`
  const translatedShowAll = t`Ver tudo`
  const translatedReadMore = t`Ler mais`

  return (
    <S.HighlightsContainer>
      <Container>
        <SectionTitle white title={translatedSectionTitle} url="/noticias" label={translatedShowAll} />
      </Container>
      <S.HighlightsGridContainer>
        {highlightsData.map(({ node: post }, index) => (
          <S.HighlightCard key={index}>
            <S.HighlightImage
              fluid={post.featured_media.localFile.childImageSharp.fluid}
              alt={post.featured_media.alt_text}
            >
              <S.HighlightsCardOverlay />
              <S.HighlightContentWrapper className={`card-${index + 1}`}>
                <S.HighlightTitle>{post.title}</S.HighlightTitle>
                <ShowMore isWhiteColor url={`/saude/${post.slug}`} label={translatedReadMore} />
              </S.HighlightContentWrapper>
            </S.HighlightImage>
          </S.HighlightCard>
        ))}
      </S.HighlightsGridContainer>
    </S.HighlightsContainer>
  )
}

Highlights.propTypes = {
  highlightsData: PropTypes.array.isRequired,
}

export default Highlights
