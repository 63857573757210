const filterOptions = [
  {
    id: 'SP',
    value: 'São Paulo',
  },
  {
    id: 'RJ',
    value: 'Rio de Janeiro',
  },
]

export default filterOptions