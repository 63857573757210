import React from 'react'
import { useRegion } from '../../../hooks/useRegion'

import * as S from './styled'

const StateFilter = ({ filterOptions, hasWhiteBg }) => {
  const { setRegionOption, getRegionOption } = useRegion()

  const changeState = state => {
    if (state === getRegionOption()) {
      return
    }
    setRegionOption(state)
  }

  const isActive = state => state === getRegionOption()
  
  return (
    <S.UnitsFilterContainer>
      {filterOptions.map(option => (
        <S.UnitsOptions
          key={option.id}
          isActive={isActive(option.id)}
          onClick={() => changeState(option.id)}
          id={option.id}
          hasWhiteBg={hasWhiteBg}
        >
          <S.UnitsOption id={option.id}>{option.value}</S.UnitsOption>
        </S.UnitsOptions>
      ))}
    </S.UnitsFilterContainer>
  )
}

export default StateFilter
