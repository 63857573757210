import styled from 'styled-components'
import { FilterContainer, FilterOptions, FilterOption } from '../../shared/state-filter-styled'

export const StaffFilterContainer = styled(FilterContainer)``

export const StaffOptions = styled(FilterOptions)`
  color: ${props => [props.theme.colors.lighterGray, props.theme.colors.secondary][Number(props.isActive)]};
  border-bottom: 2px solid ${props =>
    [props.theme.colors.lighterGray, props.theme.colors.secondary][Number(props.isActive)]};

  &:hover {
    color: ${props => props.theme.colors.secondary};
    border-color: ${props => props.theme.colors.secondary};
  }
`

export const StaffOption = styled(FilterOption)``
