import React, { useState, createContext } from 'react'

const RegionContext = createContext()

export const RegionProvider = props => {
  const [region, setRegion] = useState('SP')

  const setRegionOption = option => setRegion(option)

  const getRegionOption = () => region

  return (
    <RegionContext.Provider
      value={{
        setRegionOption,
        getRegionOption,
      }}
      {...props}
    />
  )
}

export const useRegion = () => {
  const context = React.useContext(RegionContext)
  if (context === undefined) {
    throw new Error('useRegion must be used within a RegionContext')
  }
  return context
}
